/* eslint-disable import/prefer-default-export */
import { useQuery, useLazyQuery } from '@apollo/client';
import schema from '@core_modules/setting/services/graphql/schema';

export const getCurrency = () => useQuery(schema.getCurrencySchema);
export const getStoreName = () => useQuery(schema.getStoreName);
export const generateXSiteToken = () => useLazyQuery(schema.generateXSiteToken, {
    context: {
        request: 'internal',
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
});

export default {
    getCurrency,
    getStoreName,
    generateXSiteToken,
};

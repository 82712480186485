/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-escape */
import Link from 'next/link';
import SwitcherLanguage from '@common_language';
import { distributorPortal } from '@config';
import Cookies from 'js-cookie';
import { generateXSiteToken } from '@core_modules/setting/services/graphql';

const RenderDistributorPortal = ({
    link, isLogin, t, tokenAction,
}) => {
    const { loadToken } = tokenAction;
    const distributorHandler = () => {
        loadToken().then((res) => {
            const urlPath = `login?auth=${res.data.generateXSiteToken}`;
            window.open(link + urlPath, '_blank');
        });
    };
    if (isLogin) {
        return (
            <>
                <button type="button" onClick={distributorHandler}>
                    {t('common:menu:loginAsDistributor')}
                </button>
                <style jsx>
                    {`
                        button {border:0px;background:transparent;cursor:pointer;font-size:12px;font-weight:500;
                                min-width:0;color:#fff;text-decoration:none;text-transform:uppercase;}
                        button:hover{ border-bottom: 1px solid #fff;}
                    `}
                </style>
            </>
        );
    }

    return (
        <>
            <Link href={link}>
                <a>{t('common:menu:distributorPortal')}</a>
            </Link>
            <style jsx>
                {`
                    a:hover { border-bottom: 1px solid #fff;}
                `}
            </style>
        </>
    );
};

const ViewTop = (props) => {
    const { data } = props;
    const adminId = Cookies.get('admin_id');

    let urlEnvironment = '';
    const env = window?.APP_ENV ?? false;
    const [loadToken, { loadingToken, dataToken }] = generateXSiteToken();

    switch (env) {
    case 'local':
        urlEnvironment = distributorPortal.local;
        break;
    case 'dev':
        urlEnvironment = distributorPortal.dev;
        break;
    case 'stage':
        urlEnvironment = distributorPortal.staging;
        break;
    case 'prod':
        urlEnvironment = distributorPortal.prod;
        break;
    default:
        urlEnvironment = '';
        break;
    }

    return (
        <ul>
            <li>
                <RenderDistributorPortal
                    tokenAction={{ loadToken, loadingToken, dataToken }}
                    link={urlEnvironment}
                    {...props}
                />
            </li>
            {data.customer && adminId !== undefined && adminId !== '' ? (
                <li>
                    <span className="microsite">{`(Login By ${JSON.parse(adminId)[1]})`}</span>
                </li>
            ) : null}
            <li className="language-switcher">
                <SwitcherLanguage {...props} />
            </li>

            <style jsx>
                {`
                    ul {
                        margin: 0;
                        list-style: none;
                        padding: 0;
                        float: right;
                        font-size: 10px;
                        text-transform: uppercase;
                        font-family: Montserrat !important;
                    }
                    li {
                        display: inline-block;
                        padding: 5px 10px;
                        position: relative;
                    }
                    li:hover > ul {
                        display: block;
                    }
                    ul ul {
                        position: absolute;
                        display: none;
                        margin: 0;
                        padding: 5px 10px;
                        z-index: 999;
                        background: #fff;
                        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
                    }
                    ul ul li {
                        display: block;
                    }

                    ul ul ul {
                        position: absolute;
                        top: 0;
                        left: 100%;
                    }
                    a,
                    ul button,
                    .microsite {
                        color: #fff;
                        text-decoration: none;
                        font-size: 12px;
                        font-weight: 500;
                        min-width: 0;
                    }

                    ul button span {
                        font-weight: 500;
                    }

                    a:hover {
                        border-bottom: 1px solid #fff;
                        color: #fff;
                    }
                `}
            </style>
        </ul>
    );
};
export default ViewTop;
